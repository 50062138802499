export const readdir = null;
export const stat = null;
export const readFile = null;
export const readFileSync = null;
export const writeFile = null;
export const writeFileSync = null;
export const open = null;
export const close = null;
export const read = null;
export const fstat = null;
export const createWriteStream = null;
export const _readToArrayBuffer = null;

export const isSupported = false;
